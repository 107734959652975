/* CHECK: If enabled, this interferes with steps styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Overflow container applied to containers with custom scrollbar */
.overflow-container {
  overflow: auto;
}

.overflow-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.overflow-container::-webkit-scrollbar-track {
  background: #f9f9f9;
}

.overflow-container::-webkit-scrollbar-thumb {
  background: #c9c9c9;
  border-radius: 6px;
}

.overflow-container::-webkit-scrollbar-thumb:hover {
  background: #999999;
}

/* To avoid bouncing effect on iOS devices */
body {
  overscroll-behavior: none;
}

/* Reset margins */
body,
h1,
h2,
h3,
p {
  margin: 0;
  padding: 0;
}

.h-full-minus-header {
  height: calc(100vh - 160px);
}

.max-h-wolfie-expanded {
  max-height: calc(82vh - 150px);
}

.max-h-wolfie {
  max-height: calc(62vh - 150px);
}

.overflow-text-wrap {
  overflow-wrap: anywhere;
}

.reset-base-styles img {
  max-width: 300px;
  max-height: 300px;
}

.reset-base-styles {
  all: initial;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.MuiListItemText-root {
  margin: 0;
}

.step-node {
  position: relative;
  width: 300px;
  padding: 20px;
  background-color: #f9f7f7;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 0.75rem;
  z-index: 10000;
}

.process-step-inside-node {
  position: relative;
  width: 180px;
  height: 45px;
  padding: 2px;
  background-color: #cccccc;
  border-radius: 4px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 0.75rem;
}

.step-inside-node {
  position: relative;
  width: 180px;
  height: 25px;
  padding: 2px;
  background-color: transparent;
  /* border-radius: 4px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); */
  font-size: 0.75rem;
}

.sub-step-inside-node {
  position: relative;
  width: 180px;
  height: 45px;
  padding: 2px;
  background-color: #f9f7f7;
  border-radius: 4px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 0.75rem;
}

.switch-step-inside-node {
  position: relative;
  width: 180px;
  height: 25px;
  padding: 2px;
  border: 2px solid #aaaaaa;
  border-radius: 4px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 0.75rem;
}

.trigger-node {
  padding: 5px;
  width: 180px;
  background-color: #f9f7f7;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 0.75rem;
}

.process-link-node {
  padding: 5px;
  width: 120px;
  background-color: #f9f7f7;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 0.75rem;
}


.process-node {
  padding: 15px;
  width: 200px;
  height: 120px;
  background-color: lightgoldenrodyellow;
  border: 1px solid #f9f7f7;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 0.75rem;
}

.process-node-external {
  background-color: green;
  color: white;
  height: 50px;
  padding: 5px;
}

.detailed-process-node {
  width: 320px;
  height: 380px;
}

.switch-node {
  padding: 15px;
  width: 120px;
  height: 120px;
  background-color: white;
  border: 1px solid #f9f7f7;
  border-radius: 50%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 0.75rem;
}

.react-flow__edges {
  z-index: 2 !important;
}