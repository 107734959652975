.landing-container {
  border: 0px solid black;
  width: 100%;
}

.landing-navigation {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0px;
}

.home-link {
  font-weight: bold;
  font-family: 'Poppins, Arial, sans-serif';
  padding-bottom: 4px;
  padding-left: 2px;
  padding-right: 2px;
  border-bottom: 2px solid black
}

.link {
  font-weight: bold;
  font-family: 'Poppins, Arial, sans-serif';
  padding-bottom: 4px;
  padding-left: 2px;
  padding-right: 2px;
}

.login-link {
  font-weight: bold;
  font-family: 'Poppins, Arial, sans-serif';
  border: 2px solid black;
  border-radius: 16px;
  padding: 4px 20px;
  cursor: pointer;
}

.hero {
  width: 100%;
  /* height: 608px */
}

.hero-spacer {
  height: 44px
}

.hero-images {
  position: relative;
  height: 306px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20%;
  font-family: 'Poppins, Arial, sans-serif';
  margin-right: 20%;
}

.highlights {
  display: flex;
}

.highlights-left {
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 40px;
  padding-right: 40px;
  width: 33%;
  text-align: center;
  border-right: 3px solid black;
  line-height: 1.5em;
  font-family: 'Poppins, Arial, sans-serif';
}

.highlights-center {
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 40px;
  padding-right: 40px;
  width: 33%;
  text-align: center;
  line-height: 1.5em;
  font-family: 'Poppins, Arial, sans-serif';
}

.highlights-right {
  border-left: 3px solid black;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 40px;
  padding-right: 40px;
  width: 33%;
  text-align: center;
  line-height: 1.5em;
  font-family: 'Poppins, Arial, sans-serif';
}

.hero-tagline {
  font-family: 'Poppins, Arial, sans-serif';
  margin-top: 32px;
  font-style: italic;
  font-size: 24px;
  text-align: center;
  line-height: 1.4em;
  width: 100%;
}